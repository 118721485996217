import { Provider } from '@angular/core';

import { JwtTokenStore } from '@portal/shared/utils';

export function provideJwtStore(): Provider[] {
    return [
        //
        JwtTokenStore,
    ];
}
