import { provideHttpClient, withInterceptors, withRequestsMadeViaParent } from '@angular/common/http';
import { Provider } from '@angular/core';

import { SharedDataAccessProviders } from '@portal/shared/data-access';
import {
    userReauthInterceptor,
    provideLocalizations,
    provideLocalizedCurrencyPipe,
    tenantIdInterceptor,
    userAuthInterceptor,
} from '@portal/shared/utils';

import { provideOnboardingService } from '@portal/core/utils';

import { provideJwtStore } from '../provide-jwt-store';
import { provideReauthManager } from './reauth-manager.provider';
import { provideAddAddressDialog } from '../provide-add-address-dialog';
import { provideCountriesStore } from '../provide-countries-store';

export const authenticatedDomain: Provider = [
    SharedDataAccessProviders,
    provideOnboardingService(),
    provideJwtStore(),
    provideAddAddressDialog(),
    provideLocalizedCurrencyPipe(),
    provideLocalizations(),
    provideReauthManager(),
    provideCountriesStore(),
    provideHttpClient(withRequestsMadeViaParent(), withInterceptors([tenantIdInterceptor, userReauthInterceptor, userAuthInterceptor])),
];
