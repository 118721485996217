import { provideHttpClient, withInterceptors, withRequestsMadeViaParent } from '@angular/common/http';
import { Provider } from '@angular/core';

import { onboardingInterceptor, tenantIdInterceptor, userAuthInterceptor } from '@portal/shared/utils';

import { provideOnboardingService } from '@portal/core/utils';

import { UserDataAccessProviders } from '@portal/user/data-access';

export const userDomain: Provider = [
    UserDataAccessProviders,
    provideOnboardingService(),
    provideHttpClient(withRequestsMadeViaParent(), withInterceptors([onboardingInterceptor, tenantIdInterceptor, userAuthInterceptor])),
];
