import { Provider } from '@angular/core';

import { CountriesStore } from '@portal/shared/data-access';
import { COUNTRIES_STORE } from '@portal/shared/utils';

export const provideCountriesStore: () => Provider[] = () => [
    {
        provide: COUNTRIES_STORE,
        useExisting: CountriesStore,
    },
];
